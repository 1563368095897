<template>
  <div class="performance">
    <div class="performanceChart-box">
      <div class="performanceChart" ref="performanceChart" />
    </div>
    <div class="table-box">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="green-td" rowspan="2">
              {{ $t("pageTitle.Question Type") }}
            </th>
            <th class="green-td" rowspan="2">
              <div>
                {{ $t("pageTitle.This Test") }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('pageTitle.Your performance on this test')"
                  placement="top"
                >
                  <span>
                    <i class="fa fa-question-circle question-icon"></i>
                  </span>
                </el-tooltip>
              </div>
            </th>
            <th class="green-td" rowspan="2">
              <div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('pageTitle.Your cumulative average')"
                  placement="top"
                >
                  <span>
                    {{ $t("pageTitle.My Average") }}
                    <i class="fa fa-question-circle question-icon"></i>
                  </span>
                </el-tooltip>
              </div>
            </th>
            <th class="green-td" rowspan="2">
              <div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="
                    $t('pageTitle.Everyone’s average for this question type')
                  "
                  placement="top"
                >
                  <span>
                    {{ $t("pageTitle.Average") }}
                    <i class="fa fa-question-circle question-icon"></i>
                  </span>
                </el-tooltip>
              </div>
            </th>
            <th class="green-td" rowspan="2" style="width: 20rem;">
              Questions
            </th>
          </tr>
          <tr></tr>
        </thead>
        <tbody>
          <tr v-for="(tag, index) in tags" :key="index">
            <td class="text-left" style="width:150px">
              <el-tag type="success" size="small" class="ivy-tag">
                <b>
                  {{ tag.name }}
                </b>
              </el-tag>
            </td>
            <td
              style="width:150px"
              v-html="
                getAccuracy(
                  tag.user_exam_correct_count,
                  tag.user_exam_question_count
                )
              "
            ></td>
            <!-- myAccuracy -->
            <td
              style="width:100px"
              v-html="getAvg(tag.user_correct_count, tag.user_answered_count)"
            ></td>
            <td
              style="width:100px"
              v-html="getAvg(tag.correct_count, tag.answered_count)"
            ></td>
            <td class="text-left" style="padding:5px;width: 20rem;">
              <router-link
                :to="{
                  name: testType + 'Explanations',
                  params: {
                    examId: examId,
                    userExamId: userExamId
                  },
                  query: {
                    sectionOrder: question.split('-')[0],
                    questionOrder: question.split('-')[1]
                  }
                }"
                v-for="(question, key) in orderTags(tag.question_orders)"
                :key="key"
              >
                <span
                  :class="
                    question.split('-')[2] == 1
                      ? 'view-question correct'
                      : 'view-question error'
                  "
                >
                  {{ question.split("-")[0] }}-{{ question.split("-")[1] }}
                </span>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
import echarts from "echarts";
import _ from "lodash";

export default {
  components: {},

  mixins: [],

  props: ["section", "tags", "tagType", "examId", "userExamId", "testType"],
  data() {
    return {
      charts: null
    };
  },
  computed: {
    sectionName() {
      return this.section;
    }
  },
  watch: {
    tags() {
      if (this.section === "Reading" || this.section === "Listening") {
        this.$nextTick(() => {
          if (this.tags.length > 2) {
            this.draw();
          } else {
            this.draw2();
          }
        });
      }
    }
  },

  mounted() {
    if (this.tags) {
      if (this.tags.length > 2) {
        this.draw();
      } else {
        this.draw2();
      }
    }
  },

  methods: {
    orderTags(tags) {
      tags.forEach(tag => {
        console.log(tag);
        tag = Number(tag);
      });
      return _.sortBy(tags);
    },
    draw() {
      this.charts = echarts.init(this.$refs["performanceChart"]);
      let chartData = [];
      let indicator = [];
      let max = 0;
      switch (this.section) {
        case "Reading":
          max = 100;
          break;
        case "Listening":
          max = 100;
          break;
        case "Speaking":
          max = this.speakingMaxScore;
          break;
        case "Writing_independent":
        case "Writing_integrated":
          max = this.writingMaxScore;
          break;
        default:
      }
      let myAccuracy = [];
      let average = [];
      let myAverage = [];
      for (let key in this.tags) {
        let tag = this.tags[key];
        indicator.unshift({
          name: `${tag.name}`,
          max
        });
        myAccuracy.unshift(
          this.getPer(tag.user_exam_correct_count, tag.user_exam_question_count)
        );
        average.unshift(this.getPer(tag.correct_count, tag.answered_count));
        myAverage.unshift(
          this.getPer(tag.user_correct_count, tag.user_answered_count)
        );
      }

      chartData.push({
        name: this.$t("pageTitle.This Test"),
        value: myAccuracy,
        areaStyle: {
          color: "#42a16a",
          opacity: 0.2
        }
      });
      chartData.push({
        name: this.$t("pageTitle.My Average"),
        value: myAverage,
        areaStyle: {
          color: "#ff8920",
          opacity: 0.2
        }
      });
      chartData.push({
        name: this.$t("pageTitle.Average"),
        value: average,
        areaStyle: {
          color: "#409EFE",
          opacity: 0.2
        }
      });
      const option = {
        title: {
          text: this.tagType
        },
        color: ["#42a16a", "#ff8920", "#409EFE"],
        legend: {
          data: [
            this.$t("pageTitle.This Test"),
            this.$t("pageTitle.My Average"),
            this.$t("pageTitle.Average")
          ]
        },
        radar: {
          // shape: 'circle',
          indicator: indicator,
          splitArea: {
            show: true,
            areaStyle: {
              color: ["#fff"]
            }
          },
          nameGap: 10,
          name: {
            rich: {
              a: {
                color: "white",
                align: "center",
                backgroundColor: this.themeColor,
                padding: 5,
                borderRadius: 4,
                fontWeight: 700
              }
            },
            formatter: a => {
              return `{a|${a}}`;
            }
          }
        },
        series: [
          {
            type: "radar",
            data: chartData
          }
        ]
      };
      this.charts.setOption(option);
    },
    draw2() {
      this.charts = echarts.init(this.$refs["performanceChart"]);
      let chartData = [];
      let indicator = [];
      let myAccuracy = [];
      let average = [];
      let myAverage = [];
      for (let key in this.tags) {
        let tag = this.tags[key];
        indicator.unshift(tag.name);
        myAccuracy.unshift(
          this.getPer(tag.user_exam_correct_count, tag.user_exam_question_count)
        );
        average.unshift(this.getPer(tag.correct_count, tag.answered_count));
        myAverage.unshift(
          this.getPer(tag.user_correct_count, tag.user_answered_count)
        );
      }
      chartData.push({
        name: this.$t("pageTitle.This Test"),
        barGap: 0,
        type: "bar",
        width: "40px",
        data: myAccuracy
      });
      chartData.push({
        name: this.$t("pageTitle.My Average"),
        barGap: 0,
        type: "bar",
        width: "40px",
        data: myAverage
      });
      chartData.push({
        name: this.$t("pageTitle.Average"),
        barGap: 0,
        type: "bar",
        width: "40px",
        data: average
      });
      const option = {
        title: {
          text: this.tagType
        },
        color: ["#42a16a", "#ff8920", "#409EFE"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          max: 100
        },
        yAxis: {
          type: "category",
          data: indicator
        },
        series: chartData
      };
      this.charts.setOption(option);
    },
    getPer(correctQuestionsCount, questionsCount) {
      let correctCount = correctQuestionsCount;
      let allCount = questionsCount;
      let percent = Math.round((correctCount / allCount) * 100);
      if (percent > 100) {
        percent = 100;
      }
      if (correctQuestionsCount >= 0 && questionsCount >= 0) {
        switch (this.section) {
          case "Reading":
            return percent;
          case "Listening":
            return percent;
          default:
        }
      } else {
        return 0;
      }
    },
    getSubScoreAverage(count, score) {
      const convertScoreToLevel = score => {
        if (score === 5) return "A+";
        const scoreMapLevels = [
          { level: "E", min: 1, max: 1.2 },
          { level: "E+", min: 1.2, max: 1.5 },
          { level: "D-", min: 1.5, max: 1.8 },
          { level: "D", min: 1.8, max: 2.2 },
          { level: "D+", min: 2.2, max: 2.5 },
          { level: "C-", min: 2.5, max: 2.8 },
          { level: "C", min: 2.8, max: 3.2 },
          { level: "C+", min: 3.2, max: 3.5 },
          { level: "B-", min: 3.5, max: 3.8 },
          { level: "B", min: 3.8, max: 4.2 },
          { level: "B+", min: 4.2, max: 4.5 },
          { level: "A-", min: 4.5, max: 4.8 },
          { level: "A+", min: 4.8, max: 5 }
        ];
        const targetLevel = scoreMapLevels.find(
          ({ min, max }) => min <= score && score < max
        );
        if (targetLevel) {
          return targetLevel.level;
        } else {
          return "";
        }
      };
      if (count === 0) return "-";
      return convertScoreToLevel(Number(Decimal.div(score, count)));
    },
    getAverageScore(exam_count, question_count) {
      let maxScore = null;
      switch (this.section) {
        case "Speaking":
          maxScore = this.speakingMaxScore;
          break;
        case "Writing_independent":
        case "Writing_integrated":
          maxScore = this.writingMaxScore;
          break;
        default:
      }
      if (exam_count > 0 && question_count > 0) {
        let score = (exam_count / question_count).toFixed(2);
        return `<b class="text-success">${Number(score)}/${maxScore} </b> `;
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    },
    getAccuracy(correctQuestionsCount, questionsCount) {
      let percent = Math.round((correctQuestionsCount / questionsCount) * 100);
      if (percent > 100) {
        percent = 100;
      }
      const displayAccuracy = `${correctQuestionsCount}/${questionsCount}`;
      if (correctQuestionsCount >= 0 && questionsCount >= 0) {
        if (percent <= 50) {
          return `<b class="text-danger">${displayAccuracy} (${percent}%)</b> `;
        } else {
          return `<b class="text-success">${displayAccuracy} (${percent}%)</b> `;
        }
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    },
    getAvg(correctQuestionsCount, questionsCount) {
      let percent = Math.round((correctQuestionsCount / questionsCount) * 100);
      if (percent > 100) {
        percent = 100;
      }
      if (correctQuestionsCount >= 0 && questionsCount > 0) {
        if (percent <= 50) {
          return `<b class="text-danger">${percent}%</b> `;
        } else {
          return `<b class="text-success">${percent}%</b> `;
        }
      } else {
        return "<b class='text-danger'>0%</b> ";
      }
    }
  }
};
</script>

<style scoped>
.table-box {
  width: 100%;
  overflow: auto;
}

.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  /* line-height: 16px; */
  margin-bottom: 20px;
}
.performance {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.table {
  background-color: white;
  margin: 0;
  border-radius: 5px;
}
::v-deep .view-question {
  display: inline-block;
  height: 30px;
  /* width: 30px; */
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px;
  padding: 0 5px;
}
::v-deep .task-question {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px;
  background-color: var(--themeColor);
  color: white;
}
::v-deep .correct {
  /* border: 1px solid var(--themeColor); */
}
.view-question.correct {
  /* border: 0.1rem solid #d4edda; */
  cursor: pointer;
  background-color: #d4edda;
  color: #212529 !important;
}
.view-question.error {
  /* border: 0.1rem solid #ffdadd; */
  cursor: pointer;
  background-color: #ffdadd;
  color: #212529 !important;
}
::v-deep .error {
  /* border: 1px solid red; */
}
.performanceChart-box {
  width: 100%;
  overflow: auto;
}
.performanceChart {
  width: 100%;
  height: 500px;
}
@media screen and (max-width: 1200px) {
  .performanceChart-box {
    width: 100%;
    overflow: auto;
  }
  .performance {
    margin-top: 2rem;
    padding: 10px;
  }
}
</style>
